'use client';

import { Combobox, ComboboxInput, ComboboxOptions } from '@headlessui/react';
import { useState } from 'react';
import { useI18n } from '~/locales/client';
import { twMerge } from 'tailwind-merge';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import CustomOption from './CustomOption';
import SimpleSelect from './SimpleSelect';
import { CustomLabelStyleEnum } from '~/types-and-enums/inputTypes';

export type SelectProps = {
  placeholder: string;
  options: SelectOption[];
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  value: any;
  name?: string;
  customLabelStyle?: CustomLabelStyleEnum;
  isSimpleSelect?: boolean;
};

export type SelectOption = {
  id: string;
  value: string | number;
  label?: string | number;
  labelKey?: string;
  subLabel?: string;
};

const Select = ({
  options,
  placeholder,
  onChange,
  onBlur,
  value,
  name,
  customLabelStyle,
  isSimpleSelect = true,
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    () => options.find((option) => option.id === value) || null
  );
  const t = useI18n();

  const emptyLabel = customLabelStyle
    ? t('select.isEmpty', {
        selectObject:
          customLabelStyle === CustomLabelStyleEnum.Fulfillment
            ? t('select.fulfillments')
            : t('select.invoices'),
      })
    : t('select.isEmpty.general');

  const onSelectChange = (newValue: string | null) => {
    const selectedOption = options.find((option) => option.id === newValue);
    setSelectedOption(selectedOption ?? null);
    onChange(selectedOption?.id);
  };

  if (isSimpleSelect) {
    return (
      <SimpleSelect
        options={options}
        value={selectedOption?.id}
        onChange={onSelectChange}
        onBlur={onBlur}
        name={name}
        placeholder={placeholder}
        emptyLabel={emptyLabel}
      />
    );
  }

  return (
    <Combobox value={selectedOption?.id} onChange={onSelectChange} immediate>
      <ComboboxInput
        name={name}
        placeholder={placeholder}
        displayValue={(option) => {
          const selected = options.find((opt) => opt.value === option);
          return selected ? String(selected.label) : '';
        }}
        onChange={(event) => onChange(event.target.value)}
        className={twMerge(
          'w-full rounded-3xl border bg-gray-50 px-4 py-1 bg-white',
          'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400'
        )}
      />
      <ComboboxOptions
        anchor="bottom"
        className="w-[var(--input-width)] bg-white border empty:invisible mt-2 rounded-sm [--anchor-max-height:18.725rem]"
      >
        {options.length === 0 && (
          <Flex className="py-4 px-2 justify-center">
            <Text className="text-gray-700 font-medium">{emptyLabel}</Text>
          </Flex>
        )}
        {options.map((option) => (
          <CustomOption
            key={option.value}
            option={option}
            customLabelStyle={customLabelStyle}
          />
        ))}
      </ComboboxOptions>
    </Combobox>
  );
};

export default Select;
