'use client';
import { useEffect, useState } from 'react';
import { Flex, FormLabel, Input, useMediaQuery } from '@chakra-ui/react';
import { useI18n } from '~/locales/client';
import { Button } from '../Button';

export type NumberRangeProps = {
  value?: [number | string, number | string];
  onClick: (min: number | string, max: number | string) => void;
};

const NumberRange = ({ onClick, value }: NumberRangeProps) => {
  const t = useI18n();

  const [minPrice, setMinPrice] = useState<number | string>();
  const [maxPrice, setMaxPrice] = useState<number | string>();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isLargerThan800] = useMediaQuery('(min-width: 768px)');

  const handleMinPriceChange = (e: any) => {
    const value = e?.target?.value;
    if (value === '') {
      setMinPrice('');
    } else if (!isNaN(parseInt(value))) {
      const min = parseInt(value);
      setMinPrice(min);
    }
  };

  const handleMaxPriceChange = (e: any) => {
    const value = e?.target?.value;
    if (value === '') {
      setMaxPrice('');
    } else if (!isNaN(parseInt(value))) {
      const max = parseInt(value);
      setMaxPrice(max);
    }
  };

  useEffect(() => {
    if (minPrice && maxPrice && minPrice > maxPrice) {
      setErrorMessage('wrong');
    } else {
      setErrorMessage(null);
    }
    if (!isLargerThan800) {
      onClick(minPrice ?? '', maxPrice ?? '');
    }
  }, [minPrice, maxPrice, isLargerThan800, onClick]);

  const minValue = () => {
    if (minPrice === 0 || minPrice || minPrice === '') {
      return minPrice;
    }
    return value?.[0] ?? '';
  };

  const maxValue = () => {
    if (maxPrice === 0 || maxPrice || maxPrice === '') {
      return maxPrice;
    }
    return value?.[1] ?? '';
  };
  return (
    <Flex flexDir={'column'}>
      <Flex flexDir={'row'} justifyContent="space-between" gap={6}>
        <Flex flexDir={'column'}>
          <FormLabel fontWeight={600} fontSize={12} mb={1}>
            {t('component.numberRange.minimum')}
          </FormLabel>
          <Input
            borderRadius={'full'}
            type={'text'}
            min="0"
            focusBorderColor={errorMessage ? 'brand-red.500' : 'black'}
            borderColor={errorMessage ? 'brand-red.500' : 'transparent'}
            bg={'gray.50'}
            value={minValue()}
            fontSize={14}
            onChange={handleMinPriceChange}
          />
        </Flex>
        <Flex flexDir={'column'}>
          <FormLabel fontWeight={600} fontSize={12} mb={1}>
            {t('component.numberRange.maximum')}
          </FormLabel>
          <Input
            borderRadius={'full'}
            type={'number'}
            focusBorderColor={errorMessage ? 'brand-red.500' : 'black'}
            borderColor={errorMessage ? 'brand-red.500' : 'transparent'}
            bg={'gray.50'}
            value={maxValue()}
            fontSize={14}
            onChange={handleMaxPriceChange}
          />
        </Flex>
      </Flex>
      {isLargerThan800 && (
        <Button
          onClick={() => onClick(minPrice!, maxPrice!)}
          isDisabled={!!errorMessage}
          mt={6}
          withBorder
        >
          {t('component.numberRange.show-results')}
        </Button>
      )}
    </Flex>
  );
};

export default NumberRange;
