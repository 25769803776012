import React, { Fragment } from 'react';
import {
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  Flex,
  Text,
  Stack,
  Box,
  StackDirection,
} from '@chakra-ui/react';

import { FontWeight } from '~/constants';

import { Icon } from '../Icon';

type RadioOption = {
  key: string;
  value: string;
  label: string;
  sublabel?: string;
  icon?: string;
};

export type RadioOptions = RadioOption[];

export interface RadioProps {
  radioOptions: RadioOptions;
  withIcons?: boolean;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  value?: string;
  name?: string;
  isBig?: boolean;
  direction?: 'column' | 'row' | null;
}

const RadioWithIcon = ({
  option,
  selectedValue,
  isBig,
}: {
  option: RadioOption;
  selectedValue?: string;
  isBig?: boolean;
}) => {
  const { icon, value, label, sublabel } = option;
  const isChecked = selectedValue === value;

  if (isBig)
    return (
      <label htmlFor={label}>
        <Flex
          border={
            isChecked
              ? '1px solid var(--chakra-colors-brand-red-500)'
              : '1px solid var(--chakra-colors-gray-100)'
          }
          backgroundColor={isChecked ? 'red.50' : 'white'}
          borderRadius={'xl'}
          p={6}
          flexDir={'column'}
          minW={60}
          maxW={['100%', '100%', 60]}
          height={'100%'}
          _hover={{ cursor: 'pointer' }}
        >
          <Flex
            flexDir={'column'}
            position={'relative'}
            alignItems={'flex-end'}
          >
            {icon && (
              <Box
                display={'inline-block'}
                position={'absolute'}
                alignSelf={'flex-start'}
                top={0}
                left={0}
              >
                <Icon name={icon} color={isChecked ? '#D30B0B' : '#3B3F44'} />
              </Box>
            )}
            <ChakraRadio
              id={label}
              value={value}
              _checked={{
                borderColor: 'var(--chakra-colors-brand-red-500)',
                borderWidth: '5px',
              }}
            />

            <Flex pt={6}>
              <Flex direction={'column'}>
                <Text
                  fontWeight={FontWeight.Bold}
                  fontSize={'1rem'}
                  mb={1}
                  color={'black'}
                >
                  {label}
                </Text>
                <Text
                  fontSize={'0.875rem'}
                  color={'#646D74'}
                  fontWeight={FontWeight.Normal}
                >
                  {sublabel}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </label>
    );

  return (
    <Flex
      flex={1}
      border={
        isChecked
          ? '1px solid var(--chakra-colors-brand-red-500)'
          : '1px solid var(--chakra-colors-gray-100)'
      }
      backgroundColor={isChecked ? 'red.50' : 'white'}
      borderRadius={'md'}
      p={4}
      flexDir={'column'}
    >
      <ChakraRadio
        value={value}
        _checked={{
          borderColor: 'var(--chakra-colors-brand-red-500)',
          borderWidth: '5px',
        }}
      >
        <Flex alignItems={sublabel ? 'start' : 'center'}>
          {icon && <Icon name={icon} className="ml-4 mr-2" />}
          <Flex direction={'column'}>
            <Text fontWeight={FontWeight.Normal} fontSize={'sm'}>
              {label}
            </Text>
            {sublabel && (
              <Text
                fontSize={'xs'}
                color={'gray.700'}
                fontWeight={FontWeight.Bold}
              >
                {sublabel}
              </Text>
            )}
          </Flex>
        </Flex>
      </ChakraRadio>
    </Flex>
  );
};

const Radio = ({
  radioOptions,
  onChange,
  onBlur,
  value,
  name,
  withIcons,
  isBig,
  direction = null,
}: RadioProps) => {
  const onSetValue = (value: string) => {
    onChange(value);
  };
  const stackDirection = direction
    ? direction
    : withIcons
    ? ['column', 'column', 'row', 'row']
    : ['column', 'column', 'column', 'column'];

  return (
    <ChakraRadioGroup
      onChange={onSetValue}
      onBlur={onBlur}
      name={name}
      value={value}
    >
      <Stack
        justify={isBig ? 'center' : 'unset'}
        direction={stackDirection as StackDirection}
        gap={isBig ? 6 : '0.5rem'}
      >
        {radioOptions.map((option, index) => (
          <Fragment key={index}>
            {withIcons ? (
              <RadioWithIcon
                key={option.key}
                option={option}
                selectedValue={value}
                isBig={isBig}
              />
            ) : (
              <ChakraRadio
                key={option.key}
                value={option.value}
                my={2}
                _checked={{
                  borderColor: 'var(--chakra-colors-brand-red-500)',
                  borderWidth: '5px',
                }}
              >
                {option.label}
              </ChakraRadio>
            )}
          </Fragment>
        ))}
      </Stack>
    </ChakraRadioGroup>
  );
};

export { Radio };
