import React from 'react';
import { twMerge } from 'tailwind-merge';
import { SelectOption } from './Select'; // Adjust the import path based on your file structure

type SimpleSelectProps = {
  options: SelectOption[];
  value: string | number | undefined;
  onChange: (newValue: string | null) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  name?: string;
  placeholder: string;
  emptyLabel: string;
  selectClassName?: string;
};

const SimpleSelect = ({
  options,
  value,
  onChange,
  onBlur,
  name,
  placeholder,
  emptyLabel,
  selectClassName,
}: SimpleSelectProps) => {
  return (
    <div className="relative">
      <select
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        className={twMerge(
          'w-full rounded-3xl border bg-gray-50 px-2 py-1',
          'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400',
          selectClassName
        )}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.length === 0 ? (
          <option value="">{emptyLabel}</option>
        ) : (
          options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))
        )}
      </select>
    </div>
  );
};

export default SimpleSelect;
