import { ComboboxOption } from '@headlessui/react';
import { SelectOption } from './Select';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import { twMerge } from 'tailwind-merge';
import { ProductImage } from '~/app/[locale]/home/_components/Products/ProductImage';
import { CustomLabelStyleEnum } from '~/types-and-enums/inputTypes';

type CustomOptionProps = {
  customLabelStyle?: CustomLabelStyleEnum;
  option: SelectOption;
};

const CustomOption = ({ customLabelStyle, option }: CustomOptionProps) => {
  switch (customLabelStyle) {
    case CustomLabelStyleEnum.Fulfillment:
      return (
        <ComboboxOption value={option.id}>
          {({ focus, selected }) => (
            <Flex
              className={twMerge(
                'flex-col px-2 py-2 hover:bg-gray-100 cursor-pointer',
                focus && 'bg-gray-100',
                selected && 'bg-secondary'
              )}
            >
              <Text
                className={twMerge(
                  'font-medium text-base',
                  selected ? 'text-white' : 'text-gray-900'
                )}
              >
                {option.label}
              </Text>
              <Text
                className={twMerge(
                  'font-medium text-sm',
                  selected ? 'text-gray-300' : 'text-gray-700'
                )}
              >
                {option.subLabel}
              </Text>
            </Flex>
          )}
        </ComboboxOption>
      );
    case CustomLabelStyleEnum.Product:
      return (
        <ComboboxOption value={option.id}>
          {({ focus, selected }) => (
            <Flex
              className={twMerge(
                'flex-col px-2 py-4 hover:bg-gray-100 cursor-pointer',
                focus && 'bg-gray-100',
                selected && 'bg-secondary'
              )}
            >
              <Flex className="flex-row items-center">
                <Flex className="pr-4">
                  <ProductImage
                    className={twMerge(
                      'mr-2',
                      !selected ? 'mix-blend-darken' : ''
                    )}
                    imageUrl={option.subLabel}
                  />
                </Flex>
                <Text
                  className={twMerge(
                    'text-md font-medium',
                    selected ? 'text-white' : 'text-gray-700'
                  )}
                >
                  {option.label}
                </Text>
              </Flex>
            </Flex>
          )}
        </ComboboxOption>
      );
    case CustomLabelStyleEnum.Invoice:
      return (
        <ComboboxOption value={option.id}>
          {({ focus, selected }) => (
            <Flex
              className={twMerge(
                'flex-col px-2 py-2 hover:bg-gray-100 cursor-pointer',
                focus && 'bg-gray-100',
                selected && 'bg-secondary'
              )}
            >
              <Text
                className={twMerge(
                  'font-medium text-base',
                  selected ? 'text-white' : 'text-gray-900'
                )}
              >
                {option.label}
              </Text>
              <Text
                className={twMerge(
                  'font-medium text-sm',
                  selected ? 'text-gray-300' : 'text-gray-700'
                )}
              >
                {option.subLabel}
              </Text>
            </Flex>
          )}
        </ComboboxOption>
      );
    default:
      return (
        <ComboboxOption value={option.id}>
          {({ focus }) => (
            <Flex
              className={twMerge(
                'px-2 py-2 hover:bg-gray-100 cursor-pointer',
                focus && 'bg-gray-100'
              )}
            >
              {option.label}
            </Flex>
          )}
        </ComboboxOption>
      );
  }
};

export default CustomOption;
