/**
 * Predefined styles for custom labels and extended information in select options
 * - Fulfillment = 'Fulfillment' // displays FE Number and ZIP code
 * - Product = 'Product' // Displays Product Image and Name of Product
 * - Invoice = 'Invoice' // Displays Invoice number and payment amount
 */
export enum CustomLabelStyleEnum {
  Fulfillment = 'Fulfillment',
  Product = 'Product',
  Invoice = 'Invoice',
}
